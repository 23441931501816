import React, { FC, useEffect, useState } from "react";
import { Magic, MagicNumber } from "../../../types/magic";
import { Colors } from "../../../types/colors";
import {
  BoardControllerBlock,
  BoardControllerButton,
  BoardControllerButtonContent,
  BoardControllerButtonContentCooldown,
  BoardControllerButtonContentCooldownContent,
  BoardControllerButtonImage,
} from "./BoardControllerStyles";
import Player from "../../../types/player";
import authService from "../../../services/authService";

interface IBoardController {
  side: Colors;
  currentSide: Colors | null;
  player: Player | null;
  enemy: Player | null;
  magic?: Magic | null;
  number: MagicNumber | null;
  cooldown: {
    [MagicNumber.FirstMagic]: number;
    [MagicNumber.SecondMagic]: number;
    [MagicNumber.ThirdMagic]: number;
  };
  onClick?: (eventType: Magic, eventNumber: MagicNumber) => void;
}

const BoardController: FC<IBoardController> = ({
                                                 magic,
                                                 side,
                                                 cooldown,
                                                 player,
                                                 currentSide,
                                                 onClick,
                                                 number,
                                                 enemy,
                                               }) => {
  const [firstMagic, setFirstMagic] = useState<Magic>(Magic.Freeze);
  const [secondMagic, setSecondMagic] = useState<Magic>(Magic.Defend);
  const [thirdMagic, setThirdMagic] = useState<Magic>(Magic.SpeedUp);
  const [firstMagicIcon, setFirstMagicIcon] = useState("/images/wind.png");
  const [secondMagicIcon, setSecondMagicIcon] = useState("/images/snowflake.png");
  const [thirdMagicIcon, setThirdMagicIcon] = useState("/images/shield.png");

  const [firstEnemyMagic, setFirstEnemyMagic] = useState<string>(Magic.Freeze);
  const [secondEnemyMagic, setSecondEnemyMagic] = useState<string>(Magic.Defend);
  const [thirdEnemyMagic, setThirdEnemyMagic] = useState<string>(Magic.SpeedUp);
  const [firstEnemyMagicIcon, setFirstEnemyMagicIcon] = useState("/images/wind.png");
  const [secondEnemyMagicIcon, setSecondEnemyMagicIcon] = useState("/images/snowflake.png");
  const [thirdEnemyMagicIcon, setThirdEnemyMagicIcon] = useState("/images/shield.png");

  const [loadingEnemy, setLoadingEnemy] = useState(true);
  const [isEnemyMagicLoaded, setIsEnemyMagicLoaded] = useState(false);

  const [magicUsed, setMagicUsed] = useState<boolean>(
      JSON.parse(localStorage.getItem('magicUsed') || 'true')
  );

  // Fetch player's magic
  useEffect(() => {
    async function getMagic() {
      const res = await authService.getUser();
      setFirstMagic(res.data.firstMagic);
      setSecondMagic(res.data.secondMagic);
      setThirdMagic(res.data.thirdMagic);

      setFirstMagicIcon(getMagicIcon(res.data.firstMagic));
      setSecondMagicIcon(getMagicIcon(res.data.secondMagic));
      setThirdMagicIcon(getMagicIcon(res.data.thirdMagic));
    }
    getMagic();
  }, []);

  useEffect(() => {
    async function fetchEnemyMagic() {
      if (enemy && enemy.id && !isEnemyMagicLoaded) {
        setLoadingEnemy(true);
        const res = await authService.getEnemyById(enemy.id);
        setFirstEnemyMagic(res.data.data.firstMagic);
        setSecondEnemyMagic(res.data.data.secondMagic);
        setThirdEnemyMagic(res.data.data.thirdMagic);

        setFirstEnemyMagicIcon(getEnemyMagicIcon(res.data.data.firstMagic));
        setSecondEnemyMagicIcon(getEnemyMagicIcon(res.data.data.secondMagic));
        setThirdEnemyMagicIcon(getEnemyMagicIcon(res.data.data.thirdMagic));

        setIsEnemyMagicLoaded(true);
        setLoadingEnemy(false);
      }
    }

    fetchEnemyMagic();
  }, [enemy]);

  // Update localStorage whenever magicUsed changes
  useEffect(() => {
    localStorage.setItem('magicUsed', JSON.stringify(magicUsed));
  }, [magicUsed]);

  // Functions to display magic icons
  const getMagicIcon = (magicType: Magic) => {
    switch (magicType) {
      case Magic.Freeze:
        return "/images/snowflake.png";
      case Magic.Defend:
        return "/images/shield.png";
      case Magic.SpeedUp:
        return "/images/wind.png";
      default:
        return "";
    }
  };

  const getEnemyMagicIcon = (magicType: string) => {
    switch (magicType) {
      case Magic.Freeze:
        return "/images/snowflake.png";
      case Magic.Defend:
        return "/images/shield.png";
      case Magic.SpeedUp:
        return "/images/wind.png";
      default:
        return "";
    }
  };

  const myMagics = [
    {
      image: firstMagicIcon,
      magicType: firstMagic,
      size: "60%",
      magicNumber: MagicNumber.FirstMagic,
    },
    {
      image: secondMagicIcon,
      magicType: secondMagic,
      size: "52%",
      magicNumber: MagicNumber.SecondMagic,
    },
    {
      image: thirdMagicIcon,
      magicType: thirdMagic,
      size: "50%",
      magicNumber: MagicNumber.ThirdMagic,
    },
  ];

  const opponentMagics = [
    {
      image: firstEnemyMagicIcon,
      magicType: firstEnemyMagic,
      size: "60%",
      magicNumber: MagicNumber.FirstMagic,
    },
    {
      image: secondEnemyMagicIcon,
      magicType: secondEnemyMagic,
      size: "52%",
      magicNumber: MagicNumber.SecondMagic,
    },
    {
      image: thirdEnemyMagicIcon,
      magicType: thirdEnemyMagic,
      size: "50%",
      magicNumber: MagicNumber.ThirdMagic,
    },
  ];

  const onMagicClick = (magic: Magic, magicNumber: MagicNumber) => {
    if (cooldown[magicNumber] > 0) {
      return;
    }

    onClick && onClick(magic, magicNumber);
  };

  useEffect(() => {
    if (cooldown[MagicNumber.FirstMagic] === 3 ||
        cooldown[MagicNumber.SecondMagic] === 3 ||
        cooldown[MagicNumber.ThirdMagic] === 3) {
      setMagicUsed(true);
    } else {
      if (cooldown[MagicNumber.FirstMagic] < 3 &&
          cooldown[MagicNumber.SecondMagic] < 3 &&
          cooldown[MagicNumber.ThirdMagic] < 3) {
        setMagicUsed(false);
      }
    }
  }, [cooldown]);

  return (
      <BoardControllerBlock
          isBlack={side === Colors.BLACK}
          isWhite={side === Colors.WHITE}
          side={player?.side ?? Colors.WHITE}
      >
        {/* Player's magic */}
        {player?.side === side && myMagics.map(({ image, size, magicType, magicNumber }) => (
            <BoardControllerButton
                isActive={number === magicNumber && player?.side === currentSide}
                key={`my-${magicNumber}`}
                onClick={() => player?.side === side && onMagicClick(magicType, magicNumber)}
                side={player?.side ?? Colors.WHITE}
                disabled={magicUsed}
            >
              <BoardControllerButtonContent>
                {!!cooldown[magicNumber] && (
                    <BoardControllerButtonContentCooldown cooldown={cooldown[magicNumber]}>
                      <BoardControllerButtonContentCooldownContent>
                        {cooldown[magicNumber]}
                      </BoardControllerButtonContentCooldownContent>
                    </BoardControllerButtonContentCooldown>
                )}
                <BoardControllerButtonImage alt="image" src={image} width={size} />
              </BoardControllerButtonContent>
            </BoardControllerButton>
        ))}

        {/* Opponent's magic */}
        {player?.side !== side && (
            loadingEnemy ? (
                <div></div>
            ) : (
                opponentMagics.map(({ image, size, magicType, magicNumber }) => (
                    <BoardControllerButton
                        key={`opponent-${magicNumber}`}
                        side={player?.side ?? Colors.BLACK}
                    >
                      <BoardControllerButtonContent>
                        {!!cooldown[magicNumber] && (
                            <BoardControllerButtonContentCooldown cooldown={cooldown[magicNumber]}>
                              <BoardControllerButtonContentCooldownContent>
                                {cooldown[magicNumber]}
                              </BoardControllerButtonContentCooldownContent>
                            </BoardControllerButtonContentCooldown>
                        )}
                        <BoardControllerButtonImage alt="image" src={image} width={size} />
                      </BoardControllerButtonContent>
                    </BoardControllerButton>
                ))
            )
        )}
      </BoardControllerBlock>
  );
};

export default BoardController;