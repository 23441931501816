import styled from "styled-components";
import { Colors } from "../../../types/colors";
import pxIntoRem from "../../../utils/pxIntoRem";

interface IBoardCellCircle {
  isActive?: boolean;
}

const BoardCellCircle = styled.div<IBoardCellCircle>`
  position: absolute;
  width: ${pxIntoRem(24)};
  height: ${pxIntoRem(24)};
  border-radius: ${pxIntoRem(500)};
  left: calc(50% - ${pxIntoRem(12)});
  top: calc(50% - ${pxIntoRem(12)});
  opacity: 0;
  background-color: #7b61ff;
  transition: all 0.2s;
`;

interface IBoardCell {
  isAvailable?: boolean;
  isCasting?: boolean;
  color: Colors;
  isFrozen?: boolean;
  isDefended?: boolean;
  isSpeededUp?: boolean;
  isEatable?: boolean;
  isSecondlySpeededUp?: boolean;
  isSecondlyDefended?: boolean;
}

const BoardCellBlock = styled.div<IBoardCell>`
  position: relative;
  transition: all 0.2s;
  border: ${pxIntoRem(1)} solid #a48b5d;

  background-color: ${({ color }) => (color === Colors.WHITE ? "#f9f4e2" : "#c7ab6d")};

  ${({ isCasting }) =>
    isCasting &&
    `
    background-color: rgba(255, 68, 0, 0.667);
    cursor: pointer;
  `}

  ${({ isAvailable }) =>
    isAvailable &&
    `
    cursor: pointer;

    ${BoardCellCircle} {
      opacity: 1;
    } 

    &:hover {
        cursor: pointer;

        ${BoardCellCircle} {
            opacity: .8;
        } 
    }
    `}

    ${({ isEatable }) => (isEatable ? `background-color: rgba(255, 0, 0, 0.2);` : "")}
  
    ${({ isSpeededUp, isSecondlySpeededUp, isDefended, isSecondlyDefended, isFrozen }) =>
    `
    &::before {
      content: "";
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%; 
      height: 100%;  
      opacity: ${isSpeededUp || isSecondlySpeededUp || isDefended || isFrozen ? 1 : 0};
      transition-duration: 0.5s;
      z-index: 9999;
      background: ${
        isSpeededUp && !isSecondlySpeededUp
          ? `url("/images/speeded.png")`
          : isFrozen
          ? `url("/images/frozen.png")`
          : isDefended 
          ? `url("/images/defend.png")`
          : "transparent"
      };
      ${
        isSecondlySpeededUp
          ? `box-shadow: ${pxIntoRem(0)} ${pxIntoRem(0)} ${pxIntoRem(2)} ${pxIntoRem(3)} #CD963B;outline: ${pxIntoRem(
              2
            )} solid #F5F91C;`
          : ""
      }
      background-size: cover;
      pointer-events: none;
      
    }

    &::after {
      content: "";
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%; 
      height: 100%;  
      opacity: ${isSpeededUp || isSecondlySpeededUp || isDefended || isSecondlyDefended || isFrozen ? 1 : 0};
      transition-duration: 0.5s;
      z-index: 9999;
      ${
        isSecondlySpeededUp
          ? `box-shadow: inset ${pxIntoRem(0)} ${pxIntoRem(0)} ${pxIntoRem(2)} ${pxIntoRem(1)} #CD963B;`
          : ""
      }
      ${
        isDefended && !isSecondlyDefended
          ? `box-shadow: inset ${pxIntoRem(0)} ${pxIntoRem(0)} ${pxIntoRem(2)} ${pxIntoRem(1)} #00538aaa;`
          : ""
      }
      background-size: cover;
      pointer-events: none;
    }
    `}
`;

export { BoardCellBlock, BoardCellCircle };
