enum ServerEvent {
  BoardCreate = "board:create",
  BoardLeave = "board:leave",
  BoardGet = "board:get",
  FigureMagic = "figure:magic",
  FigureMakeStep = "figure:make-step",
  FigureTransform = "figure:transform",
  Reconnect = "reconnect",
}

enum ClientEvent {
  BoardUpdate = "board:update",
  BoardCreate = "board:create",
  BoardGet = "board:get",
  DefendSound = 'defendSound',
}

export { ClientEvent, ServerEvent };
