import styled, { keyframes } from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";
import { Colors } from "../../../types/colors";

interface IBoardController {
  isWhite?: boolean;
  isBlack?: boolean;
  side: Colors;
}

const BoardControllerBlock = styled.div<IBoardController>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #F8F3E3;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: ${pxIntoRem(138)};
  height: ${pxIntoRem(310)};
  border-radius: ${pxIntoRem(100)};
  padding-block: ${pxIntoRem(20)};
  
  ${({ side }) =>
    side === Colors.BLACK &&
    `
    transform: rotate(180deg);
  `}

  ${({ isWhite }) =>
    isWhite &&
    `
    position: absolute;
    right: ${pxIntoRem(-180)};
    bottom: ${pxIntoRem(0)};
    `}

  ${({ isBlack }) =>
    isBlack &&
    `
    position: absolute;
    left: ${pxIntoRem(-180)};
    top: ${pxIntoRem(0)};
    
    `}

    @media (max-width: 1000px) {
    transform: rotate(-90deg);

    ${({ isWhite }) =>
      isWhite &&
      `
            position: absolute;
            left: 14%;
            right: auto;
            bottom: ${pxIntoRem(-260)};
            `}

    ${({ isBlack }) =>
      isBlack &&
      `
            position: absolute;
            right: 14%;
            left: auto;
            top: ${pxIntoRem(-260)};
            `}
  }

  @media (max-width: 460px) {
    transform: scale(0.8) rotate(-90deg);
    

    ${({ isWhite }) =>
    isWhite &&
    `
          bottom: ${pxIntoRem(-230)};
          left: 10%;
          `}

  ${({ isBlack }) =>
    isBlack &&
    `
          top: ${pxIntoRem(-230)};
          right: 10%;
          `}
  }
`;

interface IBoardControllerButton {
  isActive?: boolean;
  side: Colors;
}

const BoardControllerButton = styled.button<IBoardControllerButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${pxIntoRem(85)};
  height: ${pxIntoRem(85)};
  background-color: #ffffff;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  transition: all 0.5s;
  overflow: hidden;

  ${({ isActive }) => isActive && "background-color: #01000020;"}

  @media (max-width: 1000px) {
    transform: ${({ side }) => (side === Colors.BLACK ? "rotate(-90deg)" : "rotate(90deg)")};
  }
`;

const BoardControllerButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(77)};
  height: ${pxIntoRem(77)};
  background-color: transparent;
  outline: ${pxIntoRem(1)} solid #FFC700;
  border-radius: 100%;
  
`;

const cooldownAppearance = keyframes`
  0% {
    transform: translateY(-${pxIntoRem(85)});
  }
  100% {
    transform: translateY(0px)
  }
`;

interface IBoardControllerButtonContentCooldown {
  cooldown: number;
}

const BoardControllerButtonContentCooldownContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 100%;
  font-family: "Baumans", system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: ${pxIntoRem(35)};
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0em;
  text-align: left;
`;

const BoardControllerButtonContentCooldown = styled.div<IBoardControllerButtonContentCooldown>`
  position: absolute !important;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${pxIntoRem(85)};
  height: ${pxIntoRem(85)};
  background-color: #e63535;
  border-radius: 100%;
  padding: 0;
  cursor: not-allowed;
  transition: all 0.5s;
  animation-name: ${cooldownAppearance};
  animation-duration: 0.5s;
  flex-shrink: 0;

  border: ${({cooldown}) => pxIntoRem(cooldown ? 0 : 1)} solid #ad7e5c;
  ${({cooldown}) => cooldown && `border: ${pxIntoRem(2 * cooldown)} solid #df2d17`};
`;

// const BoardControllerButtonDot = styled.div`
//   position: absolute;
//   border-radius: ${pxIntoRem(5)};
//   width: ${pxIntoRem(1)};
//   height: ${pxIntoRem(1)};
//   background-color: #866551;

//   &:first-of-type {
//     left: ${pxIntoRem(11)};
//     top: ${pxIntoRem(43};
//   }

//   &:last-of-type {
//     right: ${pxIntoRem(11)};
//     top: ${pxIntoRem(43};
//   }
// `;

interface IBoardControllerButtonImage {
  width: string;
}

const BoardControllerButtonImage = styled.img<IBoardControllerButtonImage>`
  width: ${({ width }) => width};
`;

export {
  BoardControllerBlock,
  BoardControllerButton,
  BoardControllerButtonContent,
  BoardControllerButtonImage,
  BoardControllerButtonContentCooldown,
  BoardControllerButtonContentCooldownContent,
};
