import React, { useEffect, useMemo, useState } from "react";
import {Profile} from "./ProfileHeaderStyles";
import { useLocation } from "react-router-dom";
import authService from "../../services/authService";

const ProfileHeader = () => {
    const location = useLocation();
    const [photo_id, setPhotoId] = useState(""); // Перемещаем useState внутрь компонента
    const [login, setLogin] = useState("");
    const [bonus, setBonus] = useState(0);

    useEffect(() => {
        const getData = async () => {
            const res = await authService.getUser();
            setBonus(res.data.bonus);
            setLogin(res.data.login);
            if (res.data.photo_id != null && res.data.photo_id != "") {
                setPhotoId(res.data.photo_id);
            } else if (res.data.photo_id === "" || res.data.login === "" || res.data.login === "undefined") {
                setPhotoId("/images/menu_profile.png");
            }

        };
        getData();
    }, []);

    const profileDetails = [

        {
            image: photo_id,
            text: login,
            //number: bonus,
        },

    ];

    const buttons = useMemo(() => {
        return profileDetails;
    }, [location, photo_id, login, bonus]); // Добавляем photo_id в зависимости useMemo, чтобы избежать ненужных перерисовок

    return (
        <Profile avatarUrl={photo_id} username={login} ></Profile>
    );
};

export default ProfileHeader;
