    import React from 'react';

interface UserAvatarAndNameProps {
    avatarUrl: string;
    username: string;
    //bonus: number;
}

const Profile: React.FC<UserAvatarAndNameProps> = ({ avatarUrl, username  }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start', // Выравнивание по левому краю
                padding: '40px 0',
                position: 'absolute',
                top: '0px',
                right: '20px',
            }}
        >
            <div style={{ marginRight: '20px' }}> {/* Контейнер для текста */}
                <span style={{
                    fontSize: '20px',
                    textTransform: 'uppercase',
                    letterSpacing: '1.5px',
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    display: 'block' // Элементы на новой строке
                }}>{username}</span>
                {/*<span style={{*/}
                {/*    fontSize: '18px',*/}
                {/*    textTransform: 'uppercase',*/}
                {/*    letterSpacing: '1.5px',*/}
                {/*    fontWeight: 'bold',*/}
                {/*    display: 'block', // Элементы на новой строке*/}
                {/*    marginLeft: '10px',*/}
                {/*}}>Bonuses: {bonus}</span>*/}
            </div>
            <img
                src={avatarUrl}
                style={{
                    width: '110px',
                    height: '110px',
                    //backgroundColor: 'black',
                    borderRadius: '50%',
                    objectFit: 'cover'
                }}
            />
        </div>
    );
};

    export {Profile};
