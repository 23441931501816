import appConfig from "../configs/appConfig";
import User from "../types/user";
import { fetchData } from "../utils/fetch";
import UserForRequest from "../types/user_req";

interface ILoginRequest {
  req: {
    login: string;
    password: string;
    photo_id:string;
  };
  res: {
    status: "error" | "success";
    data: User;
  };
}

interface ILoginTGRequest {
  req: {
    telegram_id: number;
    password: string;
    photo_id:string;
  };
  res: {
    status: "error" | "success";
    data: User;
  };
}

interface IRegistrationRequest {
  req: {
    login: string;
    email: string;
    password: string;
    photo_id: string;
    bonus: number;
    ref_code: string;
    telegram_id: number;
  };
  res: {
    status: "error" | "success";
    data: User;
  };
}

interface IUpdateUserRequest {
  req: {
    login: string;
    email: string;
  };
  res: {
    status: "error" | "success";
    data: {
      message: string;
    };
  };
}

interface IUpdateUserBonusRequest {
  req: {
    bonus: number;
    id: number;
  };
  res: {
    status: "error" | "success";
    data: {
      message: string;
    };
  };
}

interface IUpdateUserMagicRequest {
  req: {
    firstMagic: string;
    secondMagic: string;
    thirdMagic: string;
  };
  res: {
    status: "error" | "success";
    data: {
      message: string;
    };
  };
}

interface IGetUserRequest {
  req: {};
  res: {
    status: "error" | "success";
    data: User;
  };
}

interface IGetUserRequestBonus {
  req: {};
  res: {
    status: "error" | "success";
    data: UserForRequest;
  };
}

interface IGetUserMagicRequest{
  req: {};
  res: {
    status: "error" | "success";
    data: UserForRequest;
  }
}



class AuthService {
  async login({ login, password, photo_id }: ILoginRequest["req"]): Promise<ILoginRequest["res"]> {

    const res = await fetchData(appConfig.apiUrl + "/auth/login", {

      body: JSON.stringify({
        login,
        password,
        photo_id,
      }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    return json;
  }

  async loginByTg({ telegram_id, password, photo_id }: ILoginTGRequest["req"]): Promise<ILoginTGRequest["res"]> {

    const res = await fetchData(appConfig.apiUrl + "/auth/login_tg", {

      body: JSON.stringify({
        telegram_id,
        password,
        photo_id,
      }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    return json;
  }

  async registration({ login, email, password, photo_id, bonus, ref_code, telegram_id}: IRegistrationRequest["req"]): Promise<IRegistrationRequest["res"]> {
    const res = await fetchData(appConfig.apiUrl + "/auth/registration", {
      body: JSON.stringify({
        login,
        email,
        password,
        photo_id,
        bonus,
        ref_code,
        telegram_id,
      }),
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    return json;
  }

  async updateUser({ login, email }: IUpdateUserRequest["req"]): Promise<IUpdateUserRequest["res"]> {
    const res = await fetchData(appConfig.apiUrl + "/auth/user/update", {
      body: JSON.stringify({
        login,
        email,
      }),
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    return json;
  }

  async updateUserBonus({ bonus, id }: IUpdateUserBonusRequest["req"]): Promise<IUpdateUserBonusRequest["res"]> {
    const res = await fetchData(appConfig.apiUrl + "/auth/user/update_bonus", {
      body: JSON.stringify({
        id,
        bonus,
      }),
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    return json;
  }

  async updateUserMagic({
                          firstMagic,
                          secondMagic,
                          thirdMagic,
                        }: IUpdateUserMagicRequest["req"]): Promise<IUpdateUserMagicRequest["res"]> {
    const res = await fetchData(appConfig.apiUrl + "/auth/user/magic/update", {
      body: JSON.stringify({
        firstMagic,
        secondMagic,
        thirdMagic,
      }),
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    return json;
  }

  async getUser(): Promise<IGetUserRequest["res"]> {
    const res = await fetchData(appConfig.apiUrl + "/auth/user", {
      method: "GET",
    });

    const json = await res.json();

    return json;
  }

  async getUserMagic(): Promise<IGetUserRequest["res"]> {
    const res = await fetchData(appConfig.apiUrl + "/user/magic", {
      method: "GET",
    });

    const json = await res.json();

    return json;
  }

  async getUserById(id: number): Promise<IGetUserRequest["res"]> {
    const res = await fetchData(`${appConfig.apiUrl}/auth/user/${id}`, {
      method: "GET",
    });

    let json;
    try {
      json = await res.json();
    } catch (error) {
      console.error("Failed to parse JSON from response:", error);
      return {
        status: "error",
        data: json
      };
    }

    return {
      status: "success",
      data: json,
    };
  }

  async getEnemyById(id: string): Promise<IGetUserMagicRequest["res"]> {
    const res = await fetchData(`${appConfig.apiUrl}/auth/user/${id}`, {
      method: "GET",
    });

    let json;
    try {
      json = await res.json();
    } catch (error) {
      console.error("Failed to parse JSON from response:", error);
      return {
        status: "error",
        data: json
      };
    }

    return {
      status: "success",
      data: json,
    };
  }

  async getUserByRefCode(ref_code: string): Promise<IGetUserRequestBonus["res"]> {
    const res = await fetchData(`${appConfig.apiUrl}/auth/user_ref/${ref_code}`, {
      method: "GET",
    });

    let json;
    try {
      json = await res.json();
    } catch (error) {
      console.error("Failed to parse JSON from response:", error);
      return {
        status: "error",
        data: json
      };
    }

    return {
      status: "success",
      data: json,
    };
  }


}

export default new AuthService();