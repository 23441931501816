import React, { useEffect, useRef, useState, FC, useMemo } from "react";
import { Figure, Figures } from "../../../types/figure";
import { Colors } from "../../../types/colors";
import Player from "../../../types/player";
import { AvailableCells, Cells } from "../../../types/cells";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {Magic, MagicNumber} from "../../../types/magic";
import moment from "moment";
import {
  HistoryBlock,
  HistoryBoard,
  HistoryContent,
  HistoryNavigation,
  HistoryNavigationButton,
  HistoryNavigationButtonContent,
  HistoryNavigationButtonImage,
  HistoryNavigationButtons,
  HistoryNavigationStep,
  HistoryNavigationStepArrow,
  HistoryNavigationStepCheckOrWin,
  HistoryNavigationStepFigure,
  HistoryNavigationStepMagic,
  HistoryNavigationStepText,
  HistoryNavigationSteps,
  HistoryNavigationTitle,
  HistoryTimer,
  HistoryWinner,
  HistoryWinnerButton,
  HistoryWinnerTitle,
} from "./HistoryStyles";
import BoardFigure from "../BoardFigure/BoardFigure";
import BoardController from "../BoardController/BoardController";
import BoardCell from "../BoardCell/BoardCell";
import { BoardHistory } from "../../../types/boardHistory";
import historyService from "../../../services/historyService";

interface ChosenFigure {
  figure: Figure;
  availableCells: AvailableCells;
  castlingCells: AvailableCells;
}

const History: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const boardId = params.boardId;

  const [currentSide, setCurrentSide] = useState<Colors | null>(Colors.WHITE);
  const [player, setPlayer] = useState<Player | null>(null);
  const [speededUpFigureStepsCounter, setSpeededUpFigureStepsCounter] = useState({
    [Colors.WHITE]: 0,
    [Colors.BLACK]: 0,
  });

  const [defendFigureStepsCounter, setDefendFigureStepsCounter] = useState({
    [Colors.WHITE]: 0,
    [Colors.BLACK]: 0,
  });

  const prevFreeseFigures = useRef<string[]>([]);
  const prevDefendFigures = useRef<string[]>([]);
  const prevSpeededFigures = useRef<string[]>([]);
  const prevFigures = useRef<string>("");

  const [cells, setCells] = useState<Cells>({});

  const isCellHavingFigure = (x: number, y: number): boolean => {
    return !!cells[`${x}-${y}`];
  };

  const initCells = useMemo(() => {
    const result = [];

    for (let y = 8; y >= 1; y--) {
      for (let x = 1; x <= 8; x++) {
        if ((y + x) % 2 !== 0) {
          result.push(
              <BoardCell
                  color={Colors.WHITE}
                  x={x}
                  y={y}
                  key={`${x}-${y}`}
                  isHavingFigure={isCellHavingFigure(x, y)}
                  figure={cells[`${x}-${y}`] as any}
                  speededUpFigureStepsCounter={speededUpFigureStepsCounter}
                  defendFigureStepsCounter={defendFigureStepsCounter}
              />
          );
        } else {
          result.push(
              <BoardCell
                  color={Colors.BLACK}
                  x={x}
                  y={y}
                  key={`${x}-${y}`}
                  isHavingFigure={isCellHavingFigure(x, y)}
                  figure={cells[`${x}-${y}`] as any}
                  speededUpFigureStepsCounter={speededUpFigureStepsCounter}
                  defendFigureStepsCounter={defendFigureStepsCounter}
              />
          );
        }
      }
    }

    return result;
  }, [cells]);

  const [winnerSide, setWinnerSide] = useState<Colors | null>(null);
  const [checkedSide, setCheckedSide] = useState<Colors | null>(null);

  const initFigures = useMemo(() => {
    const figuresJSX: JSX.Element[] = [];

    const figures = Object.values(cells).filter((cell) => !!cell) as Figure[];

    for (let item in figures) {
      figuresJSX.push(
          <BoardFigure
              key={figures[item].id}
              figure={figures[item]}
              isInCheck={figures[item].name === Figures.KING && checkedSide === figures[item].color}
              side={Colors.WHITE}
          />
      );
    }

    return figuresJSX;
  }, [cells]);

  const [whiteCooldown, setWhiteCooldown] = useState({
    [MagicNumber.FirstMagic]: 0,
    [MagicNumber.SecondMagic]: 0,
    [MagicNumber.ThirdMagic]: 0,
  });

  const [blackCooldown, setBlackCooldown] = useState({
    [MagicNumber.FirstMagic]: 0,
    [MagicNumber.SecondMagic]: 0,
    [MagicNumber.ThirdMagic]: 0,
  });

  const [whiteTimer, setWhiteTimer] = useState(0);
  const [blackTimer, setBlackTimer] = useState(0);

  const whiteTimerValue = useMemo(() => (whiteTimer > 0 ? whiteTimer : 0), [whiteTimer]);
  const blackTimerValue = useMemo(() => (blackTimer > 0 ? blackTimer : 0), [blackTimer]);

  const freezeAudio = useRef<HTMLAudioElement | null>(null);
  const defendAudio = useRef<HTMLAudioElement | null>(null);
  const defendSecondlyAudio = useRef<HTMLAudioElement | null>(null);
  const speededAudio = useRef<HTMLAudioElement | null>(null);
  const stepAudio = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    freezeAudio.current = loadSound("/sounds/freeze.mp3");
    defendSecondlyAudio.current = loadSound("/sounds/defend-secondly.mp3");
    speededAudio.current = loadSound("/sounds/speedup.mp3");
    stepAudio.current = loadSound("/sounds/step.mp3");
  }, []);

  const [history, setHistory] = useState<BoardHistory[]>([]);

  const [currentIdx, setCurrentIdx] = useState(0);

  const nextStep = () => setCurrentIdx((prev) => (history[prev + 1] ? prev + 1 : prev));
  const prevStep = () => setCurrentIdx((prev) => (history[prev - 1] ? prev - 1 : prev));

  const currentHistory = useMemo(() => {
    if (!history[currentIdx]) {
      return null;
    }

    const currentHistory = history[currentIdx];

    return {
      ...currentHistory,
      field: JSON.parse(currentHistory.field),
      black_cooldown: JSON.parse(currentHistory.black_cooldown),
      white_cooldown: JSON.parse(currentHistory.white_cooldown),
      defend_figure_steps_counter: JSON.parse(currentHistory.defend_figure_steps_counter),
      speeded_up_figure_steps_counter: JSON.parse(currentHistory.speeded_up_figure_steps_counter),
    };
  }, [history, currentIdx]);

  async function getData() {
    const res = await historyService.getHistory({
      boardId: boardId ?? "",
    });

    if (res.status === "success") {
      setHistory(res.data);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!currentHistory) {
      return;
    }

    const defendFigures = (Object.values(currentHistory.field).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isDefended)
        .map((figure) => figure.id);
    const freeseFigures = (Object.values(currentHistory.field).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isFrozen)
        .map((figure) => figure.id);
    const speededFigures = (Object.values(currentHistory.field).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure?.isSpeededUp)
        .map((figure) => figure.id);
    const figures = (Object.values(currentHistory.field).filter((cell) => !!cell) as Figure[])
        .filter((figure: Figure) => !!figure)
        .map((figure) => figure.x + "" + figure.y)
        .join(",");

    const currentSideDefendFigure = (Object.values(currentHistory.field).filter((cell) => !!cell) as Figure[]).find(
        (figure) => figure.color === currentSide && !!figure.isDefended
    );

    if (
        prevFreeseFigures.current.length !== freeseFigures.length &&
        freeseFigures.some((value) => !prevFreeseFigures.current.includes(value))
    ) {
      freezeAudio.current?.play().catch(console.log);
      prevFreeseFigures.current = freeseFigures;
    } else if (
        prevDefendFigures.current.length !== defendFigures.length &&
        defendFigures.some((value) => !prevDefendFigures.current.includes(value)) &&
        currentHistory.defend_figure_steps_counter[currentSide ?? Colors.WHITE] !== 1
    ) {
      defendAudio.current?.play().catch(console.log);

      prevDefendFigures.current = defendFigures;
    } else if (
        prevSpeededFigures.current.length !== speededFigures.length &&
        speededFigures.some((value) => !prevSpeededFigures.current.includes(value))
    ) {
      speededAudio.current?.play().catch(console.log);
      prevSpeededFigures.current = speededFigures;
    } else if (
        currentHistory.defend_figure_steps_counter[currentSide ?? Colors.WHITE] === 1 &&
        !!currentSideDefendFigure
    ) {
      defendSecondlyAudio.current?.play().catch(console.log);
    } else {
      if (prevFigures.current && prevFigures.current !== figures) {
        stepAudio.current?.play().catch(console.log);
        prevFigures.current = figures;
      }
    }

    setCells(currentHistory.field);
    setBlackCooldown(currentHistory.black_cooldown);
    setWhiteCooldown(currentHistory.white_cooldown);
    setWinnerSide((currentHistory.winner_side as Colors) || null);
    setCheckedSide((currentHistory.checked_side as Colors) || null);
    setWhiteTimer(currentHistory.white_timer);
    setBlackTimer(currentHistory.black_timer);
    setSpeededUpFigureStepsCounter(currentHistory.speeded_up_figure_steps_counter);
    setDefendFigureStepsCounter(currentHistory.defend_figure_steps_counter);
  }, [currentHistory]);

  function loadSound(src: string) {
    let sound: any = document.createElement("audio");

    if ("src" in sound) {
      sound.autoplay = false;
    } else {
      sound = document.createElement("bgsound");
      sound.volume = -10000;
      sound.play = function () {
        this.src = src;
        this.volume = 0;
      };
    }

    sound.src = src;
    document.body.appendChild(sound);
    return sound;
  }

  const steps = useMemo(() => {
    const correctSteps = history.slice(0, currentIdx + 1);

    interface IResultItem {
      // isChecked: boolean;
      // isWin: boolean;
      // isCastling: boolean;
      // isSecondStep: boolean;
      // figure: {
      //   cell: string;
      //   src: string;
      // };
      // magic: {
      //   cell: string;
      //   src: string;
      // };
      type: "magic" | "step" | "check" | "win" | "castling";
      entity: {
        color: Colors | null;
        src: string;
        cell: string;
      } | null;
    }

    const isMagicStep = (prevStep: BoardHistory, nextStep: BoardHistory): Figure | null => {
      const prevStepField = Object.values(JSON.parse(prevStep.field)) as Figure[];
      const nextStepField = Object.values(JSON.parse(nextStep.field)) as Figure[];

      return (
          nextStepField.find((nextCell: Figure | null) => {
            return prevStepField.some(
                (cell: Figure | null) =>
                    nextCell &&
                    cell &&
                    ((!cell?.isDefended && !!nextCell?.isDefended && cell?.id === nextCell?.id) ||
                        (!cell?.isFrozen && !!nextCell?.isFrozen && cell?.id === nextCell?.id) ||
                        (!cell?.isSpeededUp && !!nextCell?.isSpeededUp && cell?.id === nextCell?.id))
            );
          }) ?? null
      );
    };

    const isFigureStep = (prevStep: BoardHistory, nextStep: BoardHistory): Figure | null => {
      const prevStepField = Object.values(JSON.parse(prevStep.field)) as Figure[];
      const nextStepField = Object.values(JSON.parse(nextStep.field)) as Figure[];

      return (
          prevStepField.find((cell: Figure | null) => {
            return nextStepField.some((nextCell: Figure | null) => {
              return cell && nextCell && (cell?.x !== nextCell?.x || cell?.y !== nextCell?.y) && cell.id === nextCell.id;
            });
          }) ?? null
      );
    };

    const isWinStep = (nextStep: BoardHistory): boolean => {
      return !!nextStep.winner_side;
    };

    const isCheckStep = (prevStep: BoardHistory, nextStep: BoardHistory): boolean => {
      return !prevStep.checked_side && !!nextStep.checked_side;
    };

    const isCastling = (prevStep: BoardHistory, nextStep: BoardHistory): Figure | null => {
      const prevStepField = Object.values(JSON.parse(prevStep.field)) as Figure[];
      const nextStepField = Object.values(JSON.parse(nextStep.field)) as Figure[];

      const changedFigures = prevStepField.filter((cell: Figure | null) => {
        return nextStepField.some((nextCell: Figure | null) => {
          return cell && nextCell && (cell?.x !== nextCell?.x || cell?.y !== nextCell?.y) && cell.id === nextCell.id;
        });
      });

      const casting =
          changedFigures.length === 2 &&
          changedFigures.some((figure) => figure.name === Figures.KING) &&
          changedFigures.some((figure) => figure.name === Figures.ROOK);

      if (casting) {
        return changedFigures[0];
      }

      return null;
    };

    const result: IResultItem[] = [];

    for (let i = 0; i < correctSteps.length; i++) {
      const prevStep = correctSteps[i];
      const nextStep = correctSteps[i + 1];

      if (!prevStep || !nextStep || !currentHistory) {
        continue;
      }

      const magic = isMagicStep(prevStep, nextStep);
      const figure = isFigureStep(prevStep, nextStep);
      const win = isWinStep(nextStep);
      const check = isCheckStep(prevStep, nextStep);
      const castling = isCastling(prevStep, nextStep);

      if (magic) {
        const src = !!magic?.isDefended
            ? "/images/shield.png"
            : !!magic?.isSpeededUp
                ? "/images/wind.png"
                : "/images/snowflake.png";

        const cell = "abcdefgh"[magic.x - 1] + "" + magic.y;

        const item: IResultItem = {
          type: "magic",
          entity: {
            cell,
            src,
            color: null,
          },
        };

        result.push(item);
      }

      if (castling) {
        const item: IResultItem = {
          type: "castling",
          entity: {
            cell: "",
            src: "",
            color: castling.color,
          },
        };

        result.push(item);
      } else if (figure) {
        const src = `/images/figures/${(figure.color === Colors.WHITE ? "w" : "") + figure.name}.png`;

        const cell = "abcdefgh"[figure.x - 1] + "" + figure.y;

        const item: IResultItem = {
          type: "step",
          entity: {
            cell,
            src,
            color: figure.color,
          },
        };

        result.push(item);
      }

      if (win) {
        const item: IResultItem = {
          type: "win",
          entity: null,
        };

        result.push(item);
      } else if (check) {
        const item: IResultItem = {
          type: "check",
          entity: null,
        };

        result.push(item);
      }
    }

    const lines: IResultItem[][] = [];

    let line: IResultItem[] = [];

    while (result.length) {
      const item = result.shift();

      if (!item) {
        lines.push([...line]);
        line = [];
        break;
      }

      line.push(item);

      if (item.type === "win" || item.type === "castling") {
        lines.push([...line]);
        line = [];
        break;
      }

      if (item?.entity?.color === Colors.BLACK) {
        // Get check and win
        const nextItem = [...result].shift();
        const nextWinOrCheck = nextItem?.type === "check" || nextItem?.type === "win";

        if (nextWinOrCheck) {
          const item = result.shift();
          if (item) {
            line.push(item);
          }
        }

        lines.push([...line]);
        line = [];
        continue;
      }
    }

    return lines;
  }, [history, currentIdx]);

  const [magicNumber, setMagicNumber] = useState<MagicNumber | null>(null);
  const [enemy, setEnemy] = useState<Player | null>(null);

  return (
      <HistoryBlock>
        <HistoryBoard>
          <HistoryContent side={player?.side ?? Colors.WHITE}>
            <BoardController enemy={enemy} player={player} currentSide={currentSide} side={Colors.BLACK} cooldown={blackCooldown} number={magicNumber} />
            <HistoryTimer side={Colors.BLACK} player={player}>
              {moment(blackTimerValue).format("mm : ss")}
            </HistoryTimer>

            <BoardController enemy={enemy} player={player} currentSide={currentSide} side={Colors.WHITE} cooldown={whiteCooldown} number={magicNumber} />
            <HistoryTimer side={Colors.WHITE} player={player}>
              {moment(whiteTimerValue).format("mm : ss")}
            </HistoryTimer>

            {initCells}
            {initFigures}

            {winnerSide && (
                <HistoryWinner player={player}>
                  <HistoryWinnerTitle>{winnerSide == Colors.WHITE ? "White" : "Black"} won</HistoryWinnerTitle>
                </HistoryWinner>
            )}
          </HistoryContent>
        </HistoryBoard>

        <HistoryNavigation>
          <HistoryNavigationTitle>Ход партии:</HistoryNavigationTitle>

          <HistoryNavigationButtons>
            <HistoryNavigationButton onClick={prevStep} style={{ transform: 'scale(1.5)' }}>
              <HistoryNavigationButtonContent>
                <HistoryNavigationButtonImage src="/images/arrow-right.svg" isRight={false} />
              </HistoryNavigationButtonContent>
            </HistoryNavigationButton>

            <HistoryNavigationButton onClick={nextStep} style={{ transform: 'scale(1.5)' }}>
              <HistoryNavigationButtonContent >
                <HistoryNavigationButtonImage src="/images/arrow-right.svg" isRight={true}/>
              </HistoryNavigationButtonContent>
            </HistoryNavigationButton>
          </HistoryNavigationButtons>

          <HistoryNavigationSteps>
            {steps.map((line, lineIdx) => {
              return (
                  <HistoryNavigationStep key={lineIdx} onClick={() => setCurrentIdx((lineIdx + 1) * line.length)}>
                    <HistoryNavigationStepText>{lineIdx + 1}.</HistoryNavigationStepText>

                    {line.map((lineItem, idx) => {
                      let result = <></>;

                      if (lineItem.type === "castling" && lineItem.entity) {
                        const king = `/images/figures/${(lineItem.entity.color === Colors.WHITE ? "w" : "") + "king"}.png`;
                        const rook = `/images/figures/${(lineItem.entity.color === Colors.WHITE ? "w" : "") + "rook"}.png`;

                        result = (
                            <>
                              <HistoryNavigationStepFigure src={king} />
                              <HistoryNavigationStepArrow src="/images/next-step-arrow.png" disableMargin />
                              <HistoryNavigationStepFigure src={rook} />
                            </>
                        );
                      }

                      if (lineItem.type === "magic" && lineItem.entity) {
                        result = (
                            <>
                              <HistoryNavigationStepMagic src={lineItem.entity?.src} />
                              <HistoryNavigationStepText>{lineItem.entity?.cell}</HistoryNavigationStepText>
                            </>
                        );
                      }

                      if (lineItem.type === "step") {
                        result = (
                            <>
                              <HistoryNavigationStepFigure src={lineItem.entity?.src} />
                              <HistoryNavigationStepText>{lineItem.entity?.cell}</HistoryNavigationStepText>
                            </>
                        );

                        if (lineItem.entity?.color === line[idx + 1]?.entity?.color) {
                          result = (
                              <>
                                <HistoryNavigationStepFigure src={lineItem.entity?.src} />
                                <HistoryNavigationStepText>{lineItem.entity?.cell}</HistoryNavigationStepText>
                                <HistoryNavigationStepArrow src="/images/next-step-arrow.png" />
                                <HistoryNavigationStepText>{line[idx + 1]?.entity?.cell}</HistoryNavigationStepText>
                              </>
                          );
                        }

                        if (lineItem.entity?.color === line[idx - 1]?.entity?.color) {
                          result = <></>;
                        }
                      }

                      if (lineItem.type === "check" || lineItem.type === "win") {
                        result = (
                            <>
                              <HistoryNavigationStepCheckOrWin
                                  src={`/images/${lineItem.type === "win" ? "win-hash.svg" : "check-plus.png"}`}
                              />
                            </>
                        );
                      }

                      if (
                          lineItem.type === "check" ||
                          lineItem.type === "win" ||
                          (lineItem.type === "step" &&
                              lineItem.entity?.color === Colors.WHITE &&
                              line[idx + 1]?.entity?.color === Colors.BLACK) ||
                          (lineItem.type === "step" && line[idx + 1]?.type === "magic")
                          // (lineItem.type === "step" && lineItem?.entity?.color === line[idx + 1]?.entity?.color)
                      ) {
                        result = (
                            <>
                              {result}
                              <div />
                            </>
                        );
                      }

                      return result;
                    })}
                  </HistoryNavigationStep>
              );
              // return (
              //

              //     {step.magic.src && (
              //       <>
              // <HistoryNavigationStepMagic src={step.magic.src} />
              // <HistoryNavigationStepText>{step.magic.cell}</HistoryNavigationStepText>
              //       </>
              //     )}

              // <HistoryNavigationStepFigure src={step.figure.src} />

              // <HistoryNavigationStepText>{step.figure.cell}</HistoryNavigationStepText>

              //     {/* <HistoryNavigationStepArrow src="/images/next-step-arrow.png" />
              //     <HistoryNavigationStepText>{step.nextCell}</HistoryNavigationStepText> */}
              // );
            })}
          </HistoryNavigationSteps>
        </HistoryNavigation>
      </HistoryBlock>
  );
};

export default History;