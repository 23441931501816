export default {
// <<<<<<< test
//   apiUrl: "http://localhost:4700",
//   websocketUrl: "ws://localhost:4700",
// =======
   apiUrl: "https://api.magic-chess.space",
   websocketUrl: "wss://api.magic-chess.space/ws",
 };


