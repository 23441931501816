import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const PersonalBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  height: 100%;
  padding-bottom: ${pxIntoRem(28)};
  margin: auto 10px;
`;

const PersonalTitle = styled.h1`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(45)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(44)};
`;

const BonusTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(40)};
  font-weight: 700;
  line-height: ${pxIntoRem(50)};
  letter-spacing: 0%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(44)};
`;

const PersonalInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${pxIntoRem(100)};
  width: 100%;
  margin: 0 auto;
  margin-top: ${pxIntoRem(98)};
  margin-bottom: ${pxIntoRem(104)};
`;

const PersonalInput = styled.div`
  display: flex;
  flex-direction: column;
`;

const PersonalInputTitle = styled.h2`
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(25)};
  font-weight: 400;
  line-height: ${pxIntoRem(28)};
  letter-spacing: 0%;
  text-align: left;
  padding-left: ${pxIntoRem(32)};
`;

const PersonalInputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(40)};
  background-color: rgb(248, 243, 227);
  width: 100%;
  max-width: ${pxIntoRem(450)};
  height: ${pxIntoRem(72.97)};
  margin-top: ${pxIntoRem(8)};
`;

const PersonalInputField = styled.input`
  border: ${pxIntoRem(1)} solid #858585;
  border-radius: ${pxIntoRem(40)};
  width: 100%;
  max-width: ${pxIntoRem(438)};
  height: ${pxIntoRem(63.85)};
  color: rgb(0, 0, 0);
  font-family: "Coda", system-uia;
  font-size: ${pxIntoRem(30)};
  font-weight: 700;
  line-height: ${pxIntoRem(33)};
  letter-spacing: 0%;
  text-align: left;
  background-color: transparent;
  padding-left: ${pxIntoRem(25)};
`;


export {
  PersonalBlock,
  PersonalTitle,
  PersonalInputs,
  PersonalInput,
  PersonalInputField,
  PersonalInputFieldWrapper,
  PersonalInputTitle,
  BonusTitle
};
